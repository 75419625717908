<template>
  <b-container>
    <validation-observer ref="simpleRules">
      <div class="mb-3">
        <b-button squared variant="primary"> Outlet ID </b-button>
        <b-button squared variant="gradient-light" class="box-shadow-1">
          {{ this.user.branch_id }}
        </b-button>
      </div>
      <div class="d-flex justify-content-between">
        <div class="mr-4 d-flex align-items-stretch w-25">
          <b-card class="w-100">
            <div
              class="d-flex flex-column align-items-center justify-content-center mt-4"
            >
              <feather-icon icon="HomeIcon" size="55" color="#db1d6c" />
              <h3 class="mt-2">Name</h3>
            </div>
            <div class="mt-2">
              <validation-provider
                #default="{ errors }"
                name="Outlet Name"
                rules="required"
              >
                <input
                  type="text"
                  class="form-control mb-2"
                  :value="user.name"
                  aria-label="Outlet Name"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
              <validation-provider
                #default="{ errors }"
                name="Company Registration No."
              >
                <input
                  type="text"
                  class="form-control mt-2"
                  placeholder="Company Registration No."
                  aria-label="Company Registration No."
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </div>
          </b-card>
        </div>
        <div class="w-75">
          <b-card>
            <div class="d-flex justify-content-between align-items-center">
              <div
                class="d-flex flex-column align-items-center justify-content-center px-2 mr-2"
              >
                <feather-icon icon="MapPinIcon" size="55" color="#db1d6c" />
                <h3 class="mt-2">Address</h3>
              </div>
              <div class="w-100">
                <p
                  type="text"
                  class="form-control mr-2 mt-1 d-flex align-items-center"
                >
                  {{ user.branch.address }}
                </p>
              </div>
            </div>
          </b-card>
          <b-card>
            <div class="d-flex justify-content-center">
              <div
                class="d-flex flex-column align-items-center justify-content-center px-2 mr-2"
              >
                <feather-icon icon="PhoneIcon" size="55" color="#db1d6c" />
                <h3 class="mt-2">Contact</h3>
              </div>
              <div class="w-100">
                <div class="mb-2">
                  <input
                    v-model="user.phone"
                    type="text"
                    class="form-control"
                    aria-label="Phone Number"
                  />
                </div>
                <div>
                  <validation-provider
                    #default="{ errors }"
                    name="Email Address"
                    rules="required"
                  >
                    <input
                      v-model="user.email"
                      type="text"
                      class="form-control"
                      aria-label="Email Address"
                      :state="errors.length > 0 ? false : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </div>
              </div>
            </div>
          </b-card>
        </div>
      </div>
      <div class="d-flex justify-content-center align-items-center mt-3">
        <b-button
          variant="gradient-primary"
          class="box-shadow-1 px-5 w-25"
          @click="onSubmit()"
          >Save</b-button
        >
      </div>
    </validation-observer>
  </b-container>
</template>

<script>
import { extend, ValidationProvider, ValidationObserver } from 'vee-validate';
import { required } from '@validations';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';

export default {
  components: { ValidationProvider, ValidationObserver },
  data() {
    return {
      user: { branch: { address: '' }, email: '', phone: '' },
    };
  },
  mounted() {
    this.user = JSON.parse(localStorage.getItem('userData'));
  },
  methods: {
    onSubmit() {
      this.$refs.simpleRules.validate().then((success) => {
        if (success) {
          this.user.address = this.user.branch.address;
          this.$http
            .post('update_Outlet_details', this.user)
            .then((response) => {
              if (response.data.status === true) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: response.data.message,
                    icon: 'CheckIcon',
                    variant: 'success',
                  },
                });
                let newUser = {};
                newUser = this.user;
                this.$delete(newUser, 'address');
                localStorage.setItem('userData', JSON.stringify(newUser));
              } else {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: response.data.message,
                    icon: 'XIcon',
                    variant: 'warning',
                  },
                });
              }
            });
        }
      });
    },
  },
};
</script>

<style lang="scss"></style>
